<template>
  <div class="menu-wrapper">
    <div class="menu-wrapper">
      <transition name="fade" mode="out-in">


        <div class="modal_deep_dive modal_deep_dive--bgImage" v-if="activeCard.type ==='webflow_01'" key="1">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_01">
            <!-- <div class="webflow_01--row"> -->
              <div class="webflow_01--left_side">
                <div class="left_side--top">
                  <p v-html="activeCard.headline_top"></p>
                  <h1 v-html="activeCard.headline"></h1>
                </div>
                <div class="buttonwrap">
                  <HaefeleButton
                    @click="goToChat()"
                    :title="$t('views.buttons.contact_sales')"
                    :icon="'Icon-Chat.svg'"
                  />
                </div>
              </div>
              <div class="webflow_01--center"></div>
              <div class="webflow_01--right_side"></div>
            <!-- </div> -->

            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
          </div>
        </div>

        <div class="modal_deep_dive modal_deep_dive--bgImage" v-if="activeCard.type ==='webflow_01-1'" key="2">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_01-1">
              <!-- <div class="webflow_01--left_side"> -->
                <div class="left_side--top">
                  <p v-html="activeCard.headline_top"></p>
                  <h1 v-html="activeCard.headline"></h1>
                </div>
                <div class="right_side--top">
                  <p v-html="activeCard.description"></p>
                </div>
              <!-- </div> -->
          </div>
            <div class='webflow_01-1--button'>
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div v-if="!showOverview" @click="paginateMenu(active+1)" class="scroll-down vert-move">
              <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
            </div>
        </div>
        

        <div class="modal_deep_dive fullscreen-bg-03" v-if="activeCard.type ==='image-fullscreen'" key="3">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h1 v-html="activeCard.headline"></h1>
              </div>
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-04" v-if="activeCard.type ==='image-fullscreen-1'" key="4">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h1 v-html="activeCard.headline"></h1>
              </div>
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-07" v-if="activeCard.type ==='image-fullscreen-2'" key="5">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h1 v-html="activeCard.headline"></h1>
              </div>
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-09" v-if="activeCard.type ==='image-fullscreen-3'" key="6">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h1 v-html="activeCard.headline"></h1>
              </div>
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive fullscreen-bg-11" v-if="activeCard.type ==='image-fullscreen-4'" key="7">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_03">
            <div class="left_side">
              <div>
                <h1 v-html="activeCard.headline"></h1>
              </div>
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive_video" v-if="activeCard.type ==='webflow_04'" key="8">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_02">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
                
              </div>
              <img class="webflow_02--icon" src="~assets/images/Slido/05_Linear_Schieben-grafic@2x.png" alt="">
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="middle__wrapper">
              <div class="webflow_02--img">
                <img src="~assets/images/Slido/05_Linear_Schieben-IFCG@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image_1"></h2>
              </div>
            </div>
             <div class="right_side">
              <div class="webflow_02--img">
                <img src="~assets/images/Slido/05_Linear_Schieben-VFCR@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image_2"></h2>
              </div>
            </div>
            </div>
          <div v-if="this.active < this.cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive_video" v-if="activeCard.type ==='webflow_04-1'" key="9">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_02">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <img class="webflow_02--icon" src="~assets/images/Slido/05_Linear_Schieben-grafic@2x.png" alt="">
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>
            <div class="middle__wrapper">
              <div class="webflow_02--img">
                <img src="~assets/images/Slido/06_Linear_Schieben_VFT@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image_1"></h2>
              </div>
            </div>
             <div class="right_side">
              <div class="webflow_02--img">
                <img src="~assets/images/Slido/06_Linear_Schieben_VFP@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image_2"></h2>
              </div>
            </div>
            </div>
          <div v-if="this.active < this.cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05'" key="10">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <img class="webflow_05--icon" src="~assets/images/Slido/08_Flaechenbuendig-grafic@2x.png" alt="">
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="webflow_05--img">
                <img src="~assets/images/Slido/08_Flaechenbuendig-Slido Flat@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image"></h2>
              </div>
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05-1'" key="11">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <img class="webflow_05--icon" src="~assets/images/Slido/10_Faltschieben-grafic@2x.png" alt="">
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="webflow_05--img">
                <img src="~assets/images/Slido/10_Faltschieben-Slido VF@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image"></h2>
              </div>
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>

        <div class="modal_deep_dive" v-if="activeCard.type ==='webflow_05-2'" key="12">
          <div v-if="active != 1" @click="paginateMenu(active-1)" class="scroll-up">
            <img src="@/assets/images/Icon-Pfeil-runter.svg" />
          </div>
          <div class="webflow_05">
            <div class="left_side">
              <div>
                <h1 class="card__headline" v-html="activeCard.headline"></h1>
                <p v-html="activeCard.description"></p>
              </div>
              <img class="webflow_05--icon" src="~assets/images/Slido/12_Drehschieben-grafic@2x.png" alt="">
              <div class="buttonwrap">
                <HaefeleButton
                  @click="goToChat()"
                  :title="$t('views.buttons.contact_sales')"
                  :icon="'Icon-Chat.svg'"
                />
              </div>
            </div>

            <div class="right_side">
              <div class="webflow_05--img">
                <img src="~assets/images/Slido/12_Drehschieben-Slido Spinfront@2x.jpg" alt="mockup" />
                <h2 class="image-slug" v-html="activeCard.description_image"></h2>
              </div>
            </div>
          </div>
          <div v-if="!showOverview && this.active <= cards.length" @click="paginateMenu(active+1)" class="scroll-down vert-move">
            <img src="@/assets/images/Icon-Pfeil-hoch.svg" />
          </div>
        </div>


      </transition>
    </div>
    <div class="menu__sidenav">
      <div v-for="i in cards.length" :key="i" class="menu__point">
        <span @click="paginateMenu(i)" :class="['square', {active: active === i}]"></span>
        <span v-if="i != cards.length" class="line"></span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import HaefeleButton from '@/components/shared/Button';

export default {
  components: {
    HaefeleButton
  },
  props: {
    showPopup: Boolean,
    closeProduct: Function,
    openPopup: Function,
    productId: String,
    inWindow: Boolean,
    toggleShowChat: Function,
    setSelectedUser: Function,
    selectedNote: String,
    setSelectedExhibit: Function,
    goToNotes: Function,
    toggleShowProduct: Function
  },
  data: () => ({
    exhibit: null,
    active: 1,
    showOverview: false,
    cards: [
      {
        type: 'webflow_01',
        headline_top: 'Schieben ganzheitlich gedacht.',
        headline: 'Slido Furniture. <br/>Ideen in Bewegung.'
      },
      {
        type: 'webflow_01-1',
        headline_top: 'Schieben ganzheitlich gedacht.',
        headline: 'Schiebebeschläge <br/>für Möbel.',
        description:
          'Kreativität kennt keine technischen Grenzen: Deswegen hält Slido Furniture Lösungen für alle Bewegungsarten von Möbelschiebetüren bereit. Um die Auswahl und die Verarbeitung möglichst einfach zu halten, bieten Ihnen unsere Produktfamilien den größtmöglichen Variantenreichtum rund um eine Beschlaglösung.'
      },
      {
        type: 'image-fullscreen',
        headline: 'Slido Furniture. <br/>Die Öffnungsarten.',
        image: '~assets/images/Slido/03_Uebersicht-Oeffnungsarten@2x.jpg'
      },
      {
        type: 'image-fullscreen-1',
        headline: 'Die Öffnungsart.  <br/>Lineares Schieben.',
        image: '~assets/images/Slido/04_Trennseite_Lineares@2x.jpg'
      },
      {
        type: 'webflow_04',
        headline: 'Lineare Schiebevarianten 1/2.',
        description:
          'Die filigrane Konstruktion der Laufteile und -schiene machen die Slido Produktfamilie für leichte Schiebetüren zur perfekten Lösung für alle Anwendungen. Durch die ins Laufteil integrierte und in der Schiene verborgene Dämpfung sowie eine Vielzahl von Designmöglichkeiten ist diese Produktfamilie der Allrounder für alle Varianten von Vorfront- über Infront bis Mixfront-Möbel mit Holz-, Glas- oder Alurahmentüren. Besonderer Vorteil für Verarbeitung und Montage: das gleiche Laufteildesign in allen Versionen.',
        icon: '~assets/images/Slido/05_Linear_Schieben-grafic@2x.png',
        image_1: '~assets/images/Slido/05_Linear_Schieben-IFCG@2x.jpg',
        description_image_1: 'Slido Design IF C G. <br/>Infront-System für 1 oder 2 Glastüren.',
        image_2: '~assets/images/Slido/05_Linear_Schieben-VFCR@2x.jpg',
        description_image_2: 'Slido Classic VF C R. <br/>Vorfront-Lösung für 1 Tür oder übereinander laufende Türen.'
      },
      {
        type: 'webflow_04-1',
        headline: 'Lineare Schiebevarianten 2/2.',
        description:
          'Slido Classic 40/60 VF T <br/>Diese Beschlagsfamilie bedient dank edler Design- laufschiene mit optionaler Blende höchste Ansprüche an hochwertiges Schrankdesign. Das Laufteil mit sanftem Dämpfungssystem sorgt zudem für größtmöglichen Schiebekomfort. Unterschiedliche Gewichtsklassen geben Freiheit in der Anwendung von Wohnwänden bis hin zu Schlafzimmerschränken. <br/>Slido Classic 50 VF<br/>Die Produkt-Familie ermöglicht mit ihrem umfassenden modularen Vorfront-Baukastensystem und dank abgestimmter Schnellmontage-Technik grenzenlosen Gestaltungsspielraum für den Kleiderschrank – optional auch mit E-Drive und Synchro-Öffnung.',
        icon: '~assets/images/Slido/05_Linear_Schieben-grafic@2x.png',
        image_1: '~assets/images/Slido/06_Linear_Schieben_VFT@2x.jpg',
        description_image_1: 'Slido Classic 40 VF T. <br/>Oben laufende Vorfront-Lösung für 1, 2 oder 3 Türen.',
        image_2: '~assets/images/Slido/06_Linear_Schieben_VFP@2x.jpg',
        description_image_2: 'Slido Classic 50 VF P.<br/>Oben laufende Vorfront-Lösung für 2, 3 oder 4 Türen mit Rollenbodenführung.'
      },
      {
        type: 'image-fullscreen-2',
        headline: 'Die Öffnungsart. <br/>Flächenbündiges Schieben.',
        image: '~assets/images/Slido/07_Trennseite_Flächenbündig@2x.jpg'
      },
      {
        type: 'webflow_05',
        headline: 'Flächenbündige Schiebevarianten.',
        description:
          'Slido Flat 60 FB ist der flächenbündige Beschlag für verschiedenste Anwendungen. Das umfassende System mit integrierter Dämpfung und einem modularen Baukasten lässt von A wie Aluminiumrahmen über E wie Elektroantrieb bis Z wie Zubehörauswahl keine Wünsche für plane Designs offen.',
        icon: '~assets/images/Slido/08_Flaechenbuendig-grafic@2x.png',
        image: '~assets/images/Slido/08_Flaechenbuendig-Slido Flat@2x.jpg',
        description_image: 'Slido Flat 60 FB.<br/>Oben laufende Vorfront-Lösung für 1, 2 oder 3 flächenbündige Türen.'
      },
      {
        type: 'image-fullscreen-3',
        headline: 'Die Öffnungsart. <br/>Faltschieben.',
        image: '~assets/images/Slido/09_Trennseite_ Faltschieben@2x.jpg'
      },
      {
        type: 'webflow_05-1',
        headline: 'Faltschiebevarianten.',
        description:
          'Slido Fold setzt Designakzente für einen optimalen Zugang zum Schrankinhalt. Das Faltsystem zeigt sich ganz ohne Bodenführung mit stabilen und komfortablen Laufeigenschaften einzigartig in verschiedensten Anwendungen von Kleiderschränken bis Küche.',
        icon: '~assets/images/Slido/10_Faltschieben-grafic@2x.png',
        image: '~assets/images/Slido/10_Faltschieben-Slido VF@2x.jpg',
        description_image: 'Slido Fold 25/50 VF.<br/>Vorfront-, Mixfront- oder Infront-Faltschiebe-Lösung für Holz- oder Alurahmentüren.'
      },
      {
        type: 'image-fullscreen-4',
        headline: 'Die Öffnungsart. <br/>Dreheinschieben..',
        image: '~assets/images/Slido/11_Tennseite_Drehschieben@2x.jpg'
      },
      {
        type: 'webflow_05-2',
        headline: 'Drehschiebevarianten.',
        description:
          'Slido Spinfront sorgt mit einer einzigartigen Drehschiebesystematik auf Wunsch für Freiraum oder Ordnung: Die unsichtbar neben dem Möbel verstauten Türen gewähren ungestörten Zugang oder verstecken den Schrankinhalt hinter einer homogenen Front. Dank Vormontage und einfachen Einbauschritten ist Slido Spinfront die ideale Lösung überall dort, wo Wohnzonen verschmelzen wie in offenen Küchen oder begehbaren Kleiderschränken.',
        icon: '~assets/images/Slido/12_Drehschieben-grafic@2x.png',
        image: '~assets/images/Slido/12_Drehschieben-Slido Spinfront@2x.jpg',
        description_image: 'Slido Spinfront 2D.<br/>Vorfront-, Mixfront- oder Infront-Drehschiebe-Lösung für 2 Holztüren. Verdecktes Parken der Türen neben der Seitenwand.'
      }
    ]
  }),
  computed: {
    activeCard() {
      return this.cards[this.active - 1];
    }
  },
  created() {
    window.addEventListener(
      'wheel',
      _.debounce(this.handleScroll, 100, {
        leading: true,
        trailing: false
      })
    );
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll);
  },
  mounted() {
    this.getExhibit();
  },
  methods: {
    async getExhibit() {
      var url = (await this.$store.getters.isAuthorized)
        ? '/exhibit'
        : '/shared-exhibit';
      this.$nextTick(async () => {
        let resp = await this.$http.get(url, {
          params: { uuid: this.productId }
        });
        this.exhibit = resp.data;
        this.files = this.exhibit.files;
        if (this.files) {
          this.images = this.files.images;
          this.videos = this.files.videos;
        }
        this.$store.getters.isAuthorized ? this.getNotes() : '';
      });
    },
    getNotes() {
      this.$http
        .get('/notes')
        .then(resp => {
          this.checkFavorites(resp.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    openNotes() {
      this.closeProduct();
      this.setSelectedExhibit(this.exhibit);
      this.goToNotes();
    },
    handleScroll(event) {
      if (this.$store.state.popup) {
        return;
      }
      console.log(this.cards.length, this.active);
      if (this.active === this.cards.length && event.deltaY > 0) {
        this.active = 0;
      }
      if (this.active === 1 && event.deltaY < 0) {
        this.active = this.cards.length + 1;
      }
      if (event.deltaY > 0) {
        this.active += 1;
      }
      if (event.deltaY < 0) {
        this.active -= 1;
      }
    },
    paginateMenu(i) {
      this.active = i;
    },
    async goToChat() {
      this.closeProduct();
      if (this.$store.getters.isAuthorized) {
        if (
          localStorage.getItem('user-roles').includes('exhibitor') ||
          localStorage.getItem('user-roles').includes('organizer')
        ) {
          this.$router.push('/exhibitor/' + this.exhibit.contact);
        } else {
          await this.$http.post(
            'distributor-call',
            JSON.stringify({ customer: this.$store.state.user.uuid })
          );
          this.$store.state.modalMessage = 'Chat wurde angefragt. Ein Mitarbeiter meldet sich in Kürze.';
          this.$store.state.modalIcon = 'fas fa-check-circle';
          this.$store.state.modalClass = 'green-text';
          this.$store.state.modal = true;
          setTimeout(
            function() {
              this.$store.state.modal = false;
            }.bind(this),
            3000
          );
        }
      } else {
        window.location.assign(
          `mailto:${this.exhibit.contact}?Subject=${this.exhibit.title}`
        );
      }
    },
    menuToggle() {
      this.showOverview = true;
    }
  }
};
</script>

<style scoped lang="scss">

  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  .vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }
  @keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
  }

.menu-wrapper {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


.scroll-down {
  position: absolute;
  bottom: 2%;
  left: 50%;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}
.scroll-up {
  position: absolute;
  top: 2%;
  left: 50%;
  z-index: 99;

  &:hover {
    cursor: pointer;
  }
}

.btn{
  margin-left: 0 !important;
}

.mockup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.laptop_wrapper {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.preview-video {
  position: absolute;
  right: 7%;
  bottom: 6%;
  padding: 20px 45px 58px 15px;
  max-width: 883px;

  @include respond(laptop) {
    padding: 20px 49px 79px 31px;
    bottom: 8%;
    left:9%;
  }

  @include respond(laptop-small) {
    padding: 20px 40px 63px 21px;
    bottom: 14%;
  }
}

h1{
  font-size: 26px;

  @include respond(laptop){
    font-size: 22px;
  }
}

.modal_deep_dive {
  position: relative;
  width: 85vw;
  height: 75vh;
  color: white;
  background: #505050e6;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #bcbcbc;
  }
}

.modal_deep_dive--bgImage{
  background-image: url('~assets/images/Slido/01_Start-bg@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullscreen-bg-03 {
  background-image: url('~assets/images/Slido/03_Uebersicht-Oeffnungsarten@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fullscreen-bg-04 {
  background-image: url('~assets/images/Slido/04_Trennseite_Lineares@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fullscreen-bg-07 {
  background-image: url('~assets/images/Slido/07_Trennseite_Flächenbündig@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fullscreen-bg-09 {
  background-image: url('~assets/images/Slido/09_Trennseite_ Faltschieben@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fullscreen-bg-11 {
  background-image: url('~assets/images/Slido/11_Tennseite_Drehschieben@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
  .modal_deep_dive_06{
    background-image: url("~assets/images/08_WebFlow_08.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

.modal_deep_dive_video {
  position: relative;
  overflow: hidden;
  width: 85vw;
  height: 75vh;
  color: white;
  background: #505050aa;
  overflow:hidden;
  
  .left_side {
    padding: 40px;
    padding-right: 0;
  }
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #bcbcbc;

    @include respond(laptop-small) {
      font-size: 14px;
    }
  }
}

.point{
  font-family: 'Roboto';
}

.buttonwrap {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 87%;

  @include respond(laptop){
    width: 66%;
  }
}

.button {
  padding: 5px;
  text-align: left;
  max-width: 328px;
}

.webflow_01{
  &--left_side{
    width: 40vw;


    .left_side--top{
      background-color: rgba(0, 0, 0, .75);
      padding: 60px 100px 60px 40px;
      margin-bottom: 70px;
    }

    .buttonwrap{
      padding-left: 40px;
      width: 60%;
    }
  }
}

.webflow_01-1{
  background-color: rgba(0, 0, 0, .75);
  padding: 60px 100px 60px 40px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;


    .right_side--top{
      width: 34%;
      margin-left:-50px;
    }

    &--button{
        height: 100%;
        @include display-grid($gap: 35px);
        align-items: flex-start;
        width: 100%;

        }
        .left_side {
          @include display-column(center);
          justify-content: space-between;

          p{
            font-size: 15px;
          }

          .webflow_01--icon{
            width: 40%;
          }
        }
}

.webflow_02 {
  height: 100%;
  @include display-grid($gap: 35px);

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Roboto' sans-serif;
    text-transform: uppercase;
    text-align: left;
    @include respond(laptop-small) {
      font-size: 22px;
    }

  }
  .left_side {
    @include display-column(center);
    justify-content: space-between;

    p{
      font-size: 15px;
    }

    .webflow_02--icon{
      width: 40%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}
.webflow_01 {
  height: 100%;
  @include display-grid($gap: 35px);
  align-items: flex-start;
  margin-top:50px;

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Roboto' sans-serif;
    // text-transform: uppercase;
    text-align: left;
    @include respond(laptop-small) {
      font-size: 22px;
    }

  }
  .left_side {
    @include display-column(center);
    justify-content: space-between;

    p{
      font-size: 15px;
    }

    .webflow_01--icon{
      width: 40%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}
.webflow_01-1 {
  height: 100%;
  display: flex;
  flex-direction: row;

  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 26px;
    font-weight: 300;
    font-family: 'Roboto' sans-serif;
    // text-transform: uppercase;
    text-align: left;
    @include respond(laptop-small) {
      font-size: 22px;
    }

  }
  .left_side {
    @include display-column(center);
    justify-content: space-between;

    p{
      font-size: 15px;
    }

    .webflow_01--icon{
      width: 40%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }
}

.webflow_03 {
  display: flex;
  height: 100%;
  align-items: flex-start;
  .left_side {
    z-index: 1;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include respond(laptop) {
      width: 50%;
    }
  }
  .right_side {
    position: relative;
    width: 65vw;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    text-transform: uppercase;
  }
  .point {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #888888;
      font-family: "Roboto", sans-serif;
      text-align: start;
      margin-top: -35px;

      @include respond(laptop) {
        margin-top: -25px;
        font-size: 40px;
      }
    }
  }
}

.webflow_04 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }

  &--video {
    position: absolute;
    top: -12%;
    right: 32%;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 150%;
      height: 150%;

      @include respond(laptop-small) {
        width: 120%;
        height: 120%;
      }
    }

    @include respond(laptop) {
      top: -10%;
      right: 27%;
    }

    @include respond(laptop-small) {
      top: 0%;
      right: 0%;
    }
  }
}
.webflow_05 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 80px;

    p{
      font-size: 15px;
    }

    .webflow_05--icon{
      width: 40%;
    }
  }

  .image-slug{
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    padding-top: 15px;
    width: 90%;
    height: 70px;
  }

  .card__headline--right{
    text-align: left;
  }

  &--img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 90%;
    }
  }

  .right_side {
    position: relative;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }

  &--video {
    position: absolute;
    top: -12%;
    right: 32%;
    width: 100%;
    height: 100%;
    z-index: -1;

    video {
      width: 150%;
      height: 150%;

      @include respond(laptop-small) {
        width: 120%;
        height: 120%;
      }
    }

    @include respond(laptop) {
      top: -10%;
      right: 27%;
    }

    @include respond(laptop-small) {
      top: 0%;
      right: 0%;
    }
  }
}

.webflow_06 {
  display: flex;
  position: relative;
  height: 100%;

  .left_side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .right_side {
    position: relative;
    width: 49%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    img{
      height:80%;
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }
  }
}

.webflow_07 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    .point{
      margin-top: -15px;
      &__text{
        padding-bottom: 20px;
      }
      @include respond(laptop){
        margin-top: -15px;
        &__text{
          padding-bottom: 45px;
        }
      }
    }
  }
  .middle__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right_side {
    position: relative;
    width: 33%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
    padding-left: 10px;


    .point{ 
      margin-top: 30px;

      img{
        margin-top: 20px;
      }
      span{
        line-height: 30px;
        font-size: 32px;
        text-align: right;
      }
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
  }
  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__text{
      display:flex;
      align-items: baseline;
    }
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }

    img{
      width: 50%;
    }
  }
}
.webflow_08 {
  display: flex;
  position: relative;
  height: 100%;
  .left_side {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 10px;

    .point{
      // margin-top: -15px;
      &__text{
        padding-bottom: 20px;
      }
      @include respond(laptop){
        // margin-top: -15px;
        &__text{
          // padding-bottom: 45px;
        }
      }
    }
  }
  .middle__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;

    img{
      height: 60px;
    }
  }
  .right_side {
    position: relative;
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
    padding-left: 10px;


    .point{ 
      // margin-top: 30px;

      img{
        height: 60px;
      }
    }
  }
  .right_side_2 {
    position: relative;
    width: 25%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 40px; 
    padding-left: 10px;


    .point{ 
      margin-top: 45px;

      img{
        height: 60px;
      }
    }
  }
  h1 {
    margin: 25px 0;
    color: $secondaryColor;
    font-size: 28px;
  }
  .point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__text{
      display:flex;
      align-items: baseline;
    }
    span {
      font-weight: 100;
      margin-right: 20px;
      font-size: 50px;
      color: #bcbcbc;
    }

    img{
      height: 60px;
    }
  }
}

.menu {
  &__point {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__sidenav {
    margin: 0 50px 0 0;
    .line {
      height: 20px;
      width: 2px;
      background: white;

      @include respond(laptop){
        height: 15px;
      }
    }
    .square {
      margin: 10px 0;
      height: 15px;
      width: 15px;
      border: 1px solid white;
      cursor: pointer;

      @include respond(laptop){
        height: 15px;
        width: 15px;
      }

      &__overview {
        height: 9px;
        width: 9px;
        border: 1px solid white;
        cursor: pointer;
      }
    }
    .active {
      background: white;
    }
  }

  &__overview {
    width: 20px;
    height: 20px;
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;

    padding-bottom: 100px;
  }
}

.row {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
  transform: scale(1);
  -ms-transform: scale(1); /* IE 9 */
  -moz-transform: scale(1); /* Firefox */
  -webkit-transform: scale(1); /* Safari and Chrome */
  -o-transform: scale(1);
}
.fade-enter {
  opacity: 0;
  transform: scale(0);
}

.fade-leave-to {
  opacity: 0;
  transform: scale(2) translate(0px, 0px);
  -ms-transform: scale(2) translate(0px, 0px); /* IE 9 */
  -moz-transform: scale(2) translate(0px, 0px); /* Firefox */
  -webkit-transform: scale(2) translate(0px, 0px); /* Safari and Chrome */
  -o-transform: scale(2) translate(0px, 0px);
}
</style>
